body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  position: relative;
  min-height: 100%;
}
body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  cursor: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  @font-face {
    font-family: "poppins";
    src: local("poppins"),
     url("./fonts/poppins.ttf") format("truetype");
    }
    @font-face {
      font-family: "Nexa";
      src: local("Nexa"),
       url("./fonts/Nexa.ttf") format("truetype");
      }
      @font-face {
        font-family: "Itim";
        src: local("Itim"),
         url("./fonts/Itim.ttf") format("truetype");
        }
  
