.seperation{
    width: 50%;
    margin-top: 20px;
    height: 2px;
    background-color: #002494;
}
.contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'poppins';
}
.contactForm{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 600px;
flex-shrink: 0;
border-radius: 12px;
border: 4px solid #FFF;
background: #FFF;
box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
margin:50px 30px;
justify-content: space-around;
}
.map{
    
    width: 40%;
    height: 300px;
    border: 0;
    border-radius: 4px;
}
.ContactTitle{
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
}
.contactBefore{
    width: 90px;
    height: 6px;
    background-color:#E92839;
    margin-left: -40px;
}
.ContactSpan{
    color: #E92839;
    text-transform: uppercase;
    font-size: 28px;
    margin-left: 20px;
    font-weight: 600;
}
.contactDetails{
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.50);
}
.feedback-input {
    color: rgba(0, 0, 0, 0.50);
	font-family: sans-serif;
  font-weight:300;
	font-size: 14px;
    margin-left: 40px;
	border-radius: 0;
	line-height: 22px;
	background-color: rgba(255, 255, 255, 0.637);
	padding: 13px 13px 13px 13px;
	margin-bottom: 10px;
	width:60%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom: 1px solid #16213e83;
}

.feedback-input:focus{
	background: #fff;
	box-shadow: 0;
	color: #16213e83;
	outline: none;
  padding: 13px 13px 13px 13px;
}
@media only screen and (max-width: 950px) {

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'poppins';
}
.contactForm{
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 600px;
}
.map{
    margin-left: 0px;
    width: 80%;
    height: 300px;
    margin-bottom: 50px;
    border: 0;
    border-radius: 4px;
}
.ContactTitle{
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
}
.contactBefore{
    width: 90px;
    height: 6px;
    background-color:#E92839;
    margin-left: -40px;
}
.ContactSpan{
    color: #E92839;
    text-transform: uppercase;
    font-size: 28px;
    margin-left: 20px;
    font-weight: 600;
}
.contactDetails{
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.50);
}
.feedback-input {
    color: rgba(0, 0, 0, 0.50);
	font-family: sans-serif;
  font-weight:300;
	font-size: 14px;
    margin-left: 40px;
	border-radius: 0;
	line-height: 22px;
	background-color: rgba(255, 255, 255, 0.637);
	padding: 13px 13px 13px 13px;
	margin-bottom: 10px;
	width:60%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom: 1px solid #16213e83;
}

.feedback-input:focus{
	background: #fff;
	box-shadow: 0;
	color: #16213e83;
	outline: none;
  padding: 13px 13px 13px 13px;
}

}
@media only screen and (max-width: 700px) {

    .contactForm{
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 600px;
    }
    .map{
        margin-left: 0px;
        width: 90%;
        height: 200px;
        margin-bottom: 50px;
        border: 0;
        border-radius: 6px;
    }
    .ContactTitle{
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:flex-start;
    }
    .contactBefore{
        width: 100px;
        height: 4px;
        background-color:#E92839;
        margin-left: -35px;
    }
    .ContactSpan{
        color: #E92839;
        text-transform: uppercase;
        font-size: 22px;
        margin-left: 20px;
        font-weight: 600;
    }
    .contactDetails{
        font-size: 14px;
        margin-left: 10px;
        color: rgba(0, 0, 0, 0.50);
    }
    .feedback-input {
        color: rgba(0, 0, 0, 0.50);
        font-family: sans-serif;
      font-weight:300;
        font-size: 12px;
        margin-left: 40px;
        border-radius: 0;
        line-height: 22px;
        background-color: rgba(255, 255, 255, 0.637);
        padding: 13px 13px 13px 13px;
        margin-bottom: 10px;
        width:60%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
      border: 0px solid transparent;
      border-bottom: 1px solid #16213e83;
    }
    

    
    }