.signIn{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    margin-left: 30vw;
    margin-top: 20vh;
    width: 40vw;
    height: auto;
    max-height: 95vh;
    box-shadow: rgba(0, 0, 0, 0.555) 0px 10px 20px, rgba(0, 0, 0, 0.521) 0px 6px 6px;
    border-radius: 8px;
    padding: 20px;
    animation: load 3s ease-in-out;
    color: #48A1A4;
}
@keyframes load{
0%{opacity: 0;}
50%{opacity: 0.5;}
100%{opacity: 1;}
}
.err{
    color: #FF7F88;
    margin-top: 10px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: capitalize;
}
.googleLogin{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #48A1A4;
    font-size: 18px;
    text-transform: capitalize;
    font-family: 'Josefin Sans', sans-serif;
}
.googleLogo{
    width: 36px;
    background-color: #FFECE3;
    border-radius: 50%;
    cursor: pointer;
}
.ou{
    margin-bottom: 5px;
}
.loginTitle{
    color: #48A1A4;
    font-family: 'Code', sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 3px;
}



  
 
.signinRedirect{
    color: #48A1A4;
    font-size: 18px;
    text-transform: capitalize;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    line-height: 22px;
    margin-top: 30px;
   
}
.signinLink{
    color: #FF7F88;
}
@media only screen and (max-width: 950px) {
    .signIn{
        margin: 10vh 20vw;
        width: 60vw;
        height: auto;
        max-height: 70vh;
    }
    .loginTitle{
        font-size: 26px;
        letter-spacing: 3px;
    }
}
@media only screen and (max-width: 700px) {
    .signIn{
        margin: 20vh 5vw;
        width: 80vw;
        height: auto;
        max-height: 90vh;
    }
    .loginTitle{
        font-size: 16px;
        letter-spacing: 3px;
    }
    .signinRedirect{
        font-size: 12px;
    }
}