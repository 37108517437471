.news {
    height: 100vh;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.50);
    background-image: url('../../images/menu5.jpg');
  }
  .newsWelcome{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50vh;
    color: white;
    padding: 10px;
    width: 50%;
    border-radius: 16px;
    margin-top: -70vh;
    margin-left: 25%;
    text-align: center;
    text-transform: uppercase;

}
.grid_content{
position: absolute;
    margin-top: 50px;
    margin-bottom: 100px;
    height: 100%;
    width: 60%;
    margin-left: 5%;
    position: absolute;
    display: grid;
    column-gap: 100px;
    grid-template-columns: auto auto ;
    place-items: center;
   
}
@media only screen and (max-width: 950px) {
  .news {
    height: 80vh;
  }
  .newsWelcome{
    height: 30vh;
    color: white;
    background-color: rgba(240, 248, 255, 0);
    padding: 10px;
    width: 80%;
    margin-left: 10%;
    margin-top: -50vh;

}
.grid_content{
position: absolute;
    margin-top: 100px;
    grid-template-columns: auto  ;
   
}
}
@media only screen and (max-width: 700px) {
  .news {
    height: 80vh;
    background-size: cover;
    background-position: center;
  }
  .newsWelcome{
    height: 30vh;
    color: white;
    background-color: rgba(240, 248, 255, 0);
    padding: 10px;
    width: 90%;
    margin-left: 2.5%;
    margin-top: -60vh;
    font-size: 12px;

}
.grid_content{
position: absolute;
    margin-top: 100px;
    grid-template-columns: auto  ;
    row-gap: 0px;
}
}