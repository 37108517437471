
.menus {
    height: 100vh;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.50);
       
  }
   
.menuSlogan{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50vh;
  color: white;
  padding: 10px;
  width: 50%;
  border-radius: 16px;
  margin-top: -70vh;
  margin-left: 25%;
  text-align: center;
  text-transform: uppercase;
}
.menuSlogan h1{
background-color: rgba(0, 0, 0, 0.308);
backdrop-filter: blur(6px);
border-radius: 4px;
}
.allMenu{
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.menuH1{
    text-align: center;
    text-transform: uppercase;
    z-index: 99;
}
.titleMenu{
  z-index: 99;
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btnMenu{
    margin-top: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    justify-content: center;
}
.menuRotate{
  z-index: 1;
    transform: rotate(180deg);
}
.menuDiv{
  background-size:cover;
  background-position: 50%;
  background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    display: grid;
    grid-template-columns: auto ;
    align-items: center;
  transition: background-image 1.5s ease-in-out;
}
.grid_content_menu{
  background-color: rgba(250, 235, 215, 0.11);
  backdrop-filter: blur(2px);
  margin-top: 100px;
  width: 100vw;
 
}
@media only screen and (max-width: 950px) {
  .menus {
    height: 100vh;
  }
  
  
.menuSlogan{
  height: 30vh;
  color: white;
  background-color: rgba(240, 248, 255, 0);
  padding: 10px;
  width: 80%;
  margin-left: 10%;
  margin-top: -80vh;

}
.allMenu{
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.menuH1{
    text-align: center;
    text-transform: uppercase;
}
.titleMenu{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btnMenu{
  margin-top: 50px;
  grid-template-columns: auto auto auto ;
  row-gap: 5px;
}
.menuRotate{
    transform: rotate(180deg);
}
.grid_content_menu{
    margin-top: 100px;
    width: 100vw;
   
}
}
@media only screen and (max-width: 700px) {
  .menus {
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  

.menuSlogan{
     height: 30vh;
    color: white;
    background-color: rgba(240, 248, 255, 0);
    padding: 10px;
    width: 90%;
    margin-left: 2.5%;
    margin-top: -60vh;
    font-size: 12px;

}
.allMenu{
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.menuH1{
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
}
.titleMenu{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btnMenu{
  margin-top: 50px;
  grid-template-columns: auto auto ;
  row-gap: 5px;
}
.menuRotate{
    transform: rotate(180deg);
}
.grid_content_menu{
    margin-top: 100px;
    width: 100vw;
   
}
}