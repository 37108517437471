.menuCard{
    margin-top: 20px;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: black;
    background-color: #ffffffb2;
    backdrop-filter: blur(4px);
    border-radius: 8px;
    width: 50%;
    margin-left: 25%;
    padding: 10px;
    margin-bottom: 10px;
}
.highlighted {
  color: #002494;
  font-weight: 700;
}
.menuCard .fadeInChild {
  animation: fadeIn2 1.2s ease-in;
  animation-fill-mode: both;
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.composition{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: 'Itim';
    letter-spacing: 1px;
    line-height: 30px;
    width: 80%;
    animation: fadeIn2 1.2s ease-in;
}
.menuName{
    text-transform: uppercase;
    font-size: 22px;
    font-family: 'poppins';
    letter-spacing: 1px;
    color: #E92839;
    animation: fadeIn2 1s ease-in;
    text-align: center;
}
.priceMenu{
  animation: fadeIn2 1.4s ease-in;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #002494;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #E92839;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
  @media only screen and (max-width: 950px) {
    .menuCard{
      margin-top: 20px;
      height: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: black;
      backdrop-filter: blur(2px);
      border-radius: 8px;
      width: 70vw;
      margin-left: 15vw;
      padding: 10px;
      margin-bottom: 10px;
  }
  .composition{
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: 'Itim';
      letter-spacing: 1px;
      line-height: 30px;
      width: 80%;
  }
  .menuName{
      text-transform: uppercase;
      font-size: 22px;
      font-family: 'poppins';
      letter-spacing: 1px;
      color: #E92839;
      animation: fadeIn2 1s ease-in;
  }

}
@media only screen and (max-width: 700px) {
  .menuCard{
    margin-top: 20px;
    width: 90%;
    margin-left: 2.5%;
}
.composition{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Itim';
    letter-spacing: 1px;
    line-height: 26px;
    width:90%;
}
.menuName{
    font-size: 20px;
}

}