.header{
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    background-color: #002594a8 ;
    backdrop-filter: blur(8px);
    z-index: 9999;
}
.orderLink{
    text-decoration: none;
}
.orderButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .orderOptionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
  }
.logo{
    margin-top: -50px;
    width: 250px;
}
.nav{
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Nexa';
    text-transform: uppercase;
    color: #fff;
}
.navLink {
    text-decoration: none;
    color: #fff;
    transition: color 1s linear;
}
.navLink.active {
    color: red; 
  }
  
.navLink:hover {
    color:#E92839;
}
@media only screen and (max-width: 950px) {
    .header{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        position: fixed;
        background-color: #002594a8 ;
        backdrop-filter: blur(8px);
        z-index: 5;
    }
    
    .logo{
        margin-top: -50px;
        width: 250px;
    }

}
@media only screen and (max-width: 700px) {
    .header{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        position: fixed;
        background-color: #002594a8 ;
        backdrop-filter: blur(8px);
        z-index: 5;
    }
    
    .logo{
        margin-top: -35px;
        width: 200px;
    }

}