.home{
    height: 100vh;
    position: sticky;
    top: 0;
    background-image: url('../../images/home1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    filter:  brightness(70%); 
    animation: bright 3s ease-in-out forwards;
}
.orderOptionsContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  position: absolute;
}
.explore{
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 400px;
    width: 300px;
    background-color: #d9d9d986;
    margin-left: 70px;
    margin-top: 120px;
    padding: 20px 0 0 20px;
    border-radius: 8px;
    z-index: -2;
    align-items: center;
    justify-content: space-around;
}
.explorerText{
    font-family: 'poppins';
    font-size: 38px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 50px;
    z-index: 2;
}
.bandRed{
    height: 100px;
    width: 300px;
    background-color: rgba(255, 0, 0, 0.589);
    position: absolute;
   border-radius: 4px;
   margin-top: -270px;
    margin-left: 120px;
    transform: rotate(-4deg);
    z-index: -1;
    animation: redBand 3s ease-in-out forwards;
}
.aboutUs{
    position: absolute;
    width: 100%;
    height: 100vh;
    
}
.presentation{
    display: flex;
    width: 60%;
    margin-left: 20%;
    margin-top: 80px;
    flex-direction: column;
    align-items: center;
   
}
.presentationTitle{
    font-family: 'Itim';
}
.presentationParag{
    text-align: center;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}
.presentationBtn{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menuItem{
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.menuPic{
    object-fit: cover;
    width: 33vw;
    height: 400px;
    transition: all 2s;
}
.containerP {
    position: relative;
  }
  .projectOverlay {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 28px;
    line-height: 28px;
    z-index: 1;
    width: 100%;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: all 2s;
    cursor: pointer;
    font-family: 'Itim';
    color: transparent;
    text-transform: uppercase;
  }
  
  .grayBackground {
    background-color: transparent;
    transition: all 2s;
  }
  .grayBackground:hover {
backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.295);
  }
  .projectOverlay:hover{
    color: #03114de7;
  }
.show {
    opacity: 1;
  }
  .fade-in {
    border-radius: 4px;
    cursor: pointer;
    opacity: 0; 
  }
  
  
  
.fade-in.is-visible:nth-last-child(1){
    animation: fadeIn 4s ease-in-out forwards;
}
.fade-in.is-visible:nth-last-child(2){
    animation: fadeIn 3s ease-in-out forwards;
}
.fade-in.is-visible:nth-last-child(3){
    animation: fadeIn 2s ease-in-out forwards;
}
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-350px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bright {
    0% {
      filter:  brightness(10%); 
    }
    100% {
      filter:  brightness(70%); 
    }
  }
  @keyframes redBand {
    0% {
      transform: rotate(-126deg);
    }
    100% {
      transform: rotate(-4deg);
    }
  }
  @media only screen and (max-width: 950px) {
    .presentation{
      width: 80%;
      margin-left: 10%;
      margin-top: 80px;
     
  }
  }
  @media only screen and (max-width: 700px) {
    .home{
      height: 80vh;
  }
  
  .explore{
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 320px;
      width: 200px;
      background-color: #d9d9d986;
      margin-left: 30px;
      margin-top: 120px;
      padding: 10px 0 0 10px;
      border-radius: 8px;
      z-index: -2;
  }
  .explorerText{
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 2px;
      line-height: 35px;
      z-index: 2;
  }
  .bandRed{
      height: 60px;
      width: 200px;
      background-color: rgba(255, 0, 0, 0.589);
      position: absolute;
     border-radius: 4px;
     margin-top: -220px;
      margin-left: 110px;
      transform: rotate(-4deg);
      z-index: -1;
      animation: redBand 3s ease-in-out forwards;
  }
  .aboutUs{
      position: absolute;
      width: 100%;
      height: 100vh;
      
  }
  .presentation{
      display: flex;
      width: 90%;
      margin-left: 5%;
      margin-top: 80px;
      flex-direction: column;
      align-items: center;
     
  }
  .presentationTitle{
      font-size: 22px;
      
  }
  .presentationParag{
      text-align: center;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 1px;
  }
  .presentationBtn{
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .menuItem{
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  }
  .menuPic{
      object-fit: cover;
      width: 90vw;
      height: 400px;
      transition: all 2s;
      border-radius: 10px;
  }
  .fade-in.is-visible:nth-last-child(1){
      animation: fadeIn 3s ease-in-out forwards;
  }
  .fade-in.is-visible:nth-last-child(2){
      animation: fadeIn 2s ease-in-out forwards;
  }
  .fade-in.is-visible:nth-last-child(3){
      animation: fadeIn 1s ease-in-out forwards;
  }
  @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-250px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  @media only screen and (max-height: 600px) {
    .home{
      height: 110vh;
  }
  
  .explore{
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 260px;
      width: 200px;
      margin-top: 100px;
  }
  .explorerText{
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 2px;
      line-height: 35px;
      z-index: 2;
  }
  .bandRed{
    height: 60px;
    width: 200px;
    position: absolute;
   border-radius: 4px;
   margin-top: -220px;
    margin-left: 110px;
}
}