.footer{
    height: 100px;
    width: 100%;
box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
padding-bottom: 10px;
}
.footerItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.footerItem.adress{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
}
.footerTitle{
    color:#002494 ;
    text-transform: uppercase;

}
.socialMedia{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.footerNav {
    list-style: none;
  
}
.footLink{
    text-decoration: none;
    color: #002494;
    font-size: 16px;
    font-family: 'poppins';
    transition: color 1s linear;
}
.footLink:hover {
    color:#E92839;
}
@media only screen and (max-width: 700px) {
    .footer{
        height: auto;
        width: 100%;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 10px;
    }
    .footerItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .footerItem.adress{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .footerTitle{
        color:#002494 ;
        text-transform: uppercase;
    
    }
    .socialMedia{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footerNav {
        width: 140%;
        list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: -40%;
    }
    .footLink{
        text-decoration: none;
        color: #002494;
        font-size: 16px;
        font-family: 'poppins';
        transition: color 1s linear;
        
    }
    .footLink:hover {
        color:#E92839;
    }
}