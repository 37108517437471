.createBrand{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    margin-left: 22.5vw;
    margin-top: 20vh;
    margin-bottom: 20px;
    width: 50vw;
    height: auto;
    max-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.555) 0px 10px 20px, rgba(0, 0, 0, 0.521) 0px 6px 6px;
    border-radius: 8px;
    padding: 20px;
    animation: load 3s ease-in-out;
    background-color: #F7F7F7;
}
.createArticle{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: auto;
    max-height: 90vh;
  }
.connectMsg{
    text-transform: capitalize;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 20px;
    font-family: 'Code';
    text-align: center;
}
@keyframes load{
0%{opacity: 0;}
50%{opacity: 0.5;}
100%{opacity: 1;}
}
.signinTitle{
    font-family: 'Code', sans-serif;
    font-size: 26px;
    letter-spacing: 2px;
    margin-bottom: 3vh;
    color: #810e05;
}
.textInput{
    width: 350px;
    height: 30px;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    background: rgba(255,255,255,0.1);
    border: 1px solid rgba(0, 0, 0, 0.562);
    border-radius: 6px;
    font-size: 16px;
    margin-top: 10px;
    padding: 15px;
    background-color: #F7F7F7;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 10px;
}
.logoLabel {
    align-self: center;
    font-family: 'Josefin Sans', sans-serif;
    background-color: #48A1A4;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 4px;
    border: solid #810e05;
    border-width: 1px;
    box-shadow:   15px 28px 25px -18px #F7F7F7;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 2px;
    line-height: 18px;
    outline: none;
    padding: 6px 5px;
    margin-bottom: 40px;
    margin-top: 20px;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    }
    #fileInput {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    
    .logoLabel:hover {
        box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
        transform: translate3d(0, 2px, 0);
      }
      
    .logoLabel:focus {
        box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
      }
 
      
@media only screen and (max-width: 950px) {
    .createBrand{
        margin: 15vh 15vw;
        width: 70vw;
        max-height: 70vh;
    }
    .signinTitle{
        font-size: 22px;
    }
    .logoLabel{
        font-size: 14px;
    }
    .createArticle{
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
      }

}
@media only screen and (max-width: 700px) {
    .createBrand{
        margin: 100px 5vw;
        width: 80vw;
        height: auto;
        max-height: 90vh;
    }
    .shopBrand{
        width: 80px;
        left: -10px;
        top:-10px

    }
    .signinTitle{
        font-size: 20px;
    }
    .connectMsg{
        text-transform: capitalize;
        font-size: 12px;
    }
    .logoLabel{
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 18px;
        margin-top: 10px;
    }
    .textInput{
        width: 230px;
        height: 50px;
        border-radius: 6px;
        text-align: center;
        font-size: 12px;
    }
    .submitBtn {
        font-size:12px;
        padding:1px 4px;
        line-height: 19px;
        text-shadow:2px 1px 2px #810e05;
    }
}